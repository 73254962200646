export const decodeQueryStringToDeck = (queryString, cardType, cardData) => {
    const cardInfoArray = queryString.split(',');

    // Use card keys as identifiers
    const decodedDeck = cardInfoArray.map((cardInfo) => {
        const [key, count] = cardInfo.split(':');
        const foundCard = cardData && cardData.find(
            (card) => card['Key'] === key
        );

        if (foundCard) {
            return { ...foundCard, count: parseInt(count, 10) || 1 };
        } else {
            return null;
        }
    });

    console.log('Decoded Deck:', decodedDeck);
    return Array.from(new Set(decodedDeck.filter(Boolean))); // Remove duplicates and filter out undefined values

};
