import React, { useState, useRef, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import BarGraph from './BarGraph';
import './DeckList.css';
import {
    Select, MenuItem, Checkbox, Button, Menu, useMediaQuery,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    p: 0,
    width: '100%',
    backgroundColor: '#25262A',
    height: 'fit-content',
    bgcolor: '#25262A',
};

const listItemStyle = {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    padding: 0,
    position: 'relative',
};

const imageContainerStyle = {
    flex: 'none',
    minWidth: '20%',
    maxWidth: '50%',
    height: '100%',
    marginLeft: 16,
};

const countStyle = {
    color: 'white',
    width: '50px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //borderLeft: 'solid 1px #1d1e20'
};

const imageStyle = {
    backgroundSize: '175%',
    backgroundPositionX: '40%',
    backgroundPositionY: '15%',
    backgroundRepeat: 'no-repeat',
    height: '100%',
};

const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.3s',
    zIndex: 1,
};

const buttonStyle = {
    backgroundColor: 'white',
    color: 'black',
    border: 'none',
    padding: '8px',
    margin: '0 4px',
    cursor: 'pointer',
};

const overlayStyleDeckStats = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    transition: 'opacity 0.3s',
    zIndex: 1,
};

const CardList = ({ cards, selectedCardType, handleRightClick, handleRemoveOne, handleCardClick, handleClearDeckBuilder }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [clickedIndex, setClickedIndex] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const listRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:768px)');
    const [showOverlay, setShowOverlay] = useState(false);

    // Calculate total number of cards in the deck
    const totalCards = cards.reduce((total, card) => {
        if ((card["Category"].toLowerCase() !== 'leader') && (card["Category"].toLowerCase() !== 'digi-egg')) {
            return total + card.count;
        }
        return total;
    }, 0);

    // Calculate the count for each rarity
    const categoryCount = cards.reduce((counts, card) => {
        counts[card.Category] = (counts[card.Category] || 0) + card.count;
        return counts;
    }, {});

    // Calculate the count for each cost
    const costCount = cards.reduce((counts, card) => {
        const cost = card.Cost.Generic;
        const count = card.count;
        const costKey = `${cost} Cost`;
        if (card["Category"].toLowerCase() !== 'leader') {
            counts[costKey] = (counts[costKey] || 0) + count;
        }
        return counts;
    }, {});

    // Sort the cost count
    const costCountArray = Object.entries(costCount);
    costCountArray.sort((a, b) => parseInt(a[0].replace("Cost ", "")) - parseInt(b[0].replace("Cost ", "")));
    const sortedCostCount = Object.fromEntries(costCountArray);

    const counterCounts = cards.reduce((acc, card) => {
        const counterValue = card.Counter;
        const count = card.count || 1; // Use card.count if it exists, otherwise default to 1
        acc[counterValue] = (acc[counterValue] || 0) + count;
        return acc;
    }, {});

    // Calculate the count for each level
    const levelCount = cards.reduce((counts, card) => {
        const level = card.Level;
        const count = card.count;
        const levelKey = `Level ${level}`;
        if (level > 0) {
            counts[levelKey] = (counts[levelKey] || 0) + count;
        }
        return counts;
    }, {});

    // Sort the level count
    const levelCountArray = Object.entries(levelCount);
    levelCountArray.sort((a, b) => parseInt(a[0].replace("Level ", "")) - parseInt(b[0].replace("Level ", "")));
    const sortedLevelCount = Object.fromEntries(levelCountArray);

    // Calculate the count for each card type
    const cardTypesCount = cards.reduce((counts, card) => {
        card['Type'].forEach(type => {
            counts[type] = (counts[type] || 0) + card.count;
        });
        return counts;
    }, {});

    const handleClickOutside = (event) => {
        if (listRef.current && !listRef.current.contains(event.target)) {
            setClickedIndex(null);
        }
    };

    const handleClear = () => {
        if (typeof handleClearDeckBuilder === 'function') {
            handleClearDeckBuilder();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleListItemClick = (index) => {
        if (!showOverlay) {
            if (isMobile) {
                setClickedIndex(clickedIndex === index ? null : index);
            }
        }
    };

    const handleOverlayClick = () => {
        setShowOverlay(!showOverlay);
    };

    const handleButtonClick = (action, card, event) => {
        event.stopPropagation();
        if (!isMobile || clickedIndex !== null) {
            if (action.toString().includes('handleRightClick')) {
                action(event, card);
            } else {
                action(card);
            }
        }
        else if (action.toString().includes('handleRightClick')) {
            action(event, card);
        } else {
            action(card);
        }
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const [handleVisibility, setHandleVisibility] = useState(true);
    const handleToggleVisibility = () => {
        setHandleVisibility(prevState => !prevState);
    }

    const renderCardList = () => (
        <List sx={style} aria-label="card list" ref={listRef}>
            <ListItem>
                <div className="right-side-container">
                    <div className="deckStatsList">
                        <div className={`rarityList ${totalCards > (selectedCardType.toLowerCase() === 'fusionworld' ? 60 : (selectedCardType.toLowerCase() === 'optcg' || 'digimon' ? 50 : 0)) ? "exceeded-limit" : ""} ${totalCards >= 30 && totalCards <= 49 ? "highlight-yellow" : ""}`}>
                            <div className={"rarityTitle"}>Total</div>
                            <div className={"rarityCount"}>{totalCards}</div>
                        </div>
                    </div>
                </div>
                <Button id="NavButton"
                    onClick={handleOverlayClick}>
                    Stats
                </Button>
                <Button id="NavButton"
                    onClick={handleClear}>
                    Clear
                </Button>

            </ListItem>
            {showOverlay && (
                <div style={{ ...overlayStyleDeckStats, top: '52.5px' }}>
                    <div className="overlayDeckListStats">

                        {(selectedCardType === 'optcg' || selectedCardType === 'fusionworld' || selectedCardType === 'unionarena') && (
                            <div className="cardTypesList">
                                {Object.entries(categoryCount).map(([rarity, count]) => {
                                    if (rarity === 'Digi-Egg') {
                                        rarity = "Egg";
                                    }
                                    return (
                                        <div key={rarity} className="rarityList">
                                            <div title={rarity} className="rarityTitle">{rarity.substring(0, 1)}</div>
                                            <div className="rarityCount">{count}</div>
                                        </div>
                                    );
                                })}
                                {Object.entries(cardTypesCount).map(([type, count]) => (
                                    <div key={type} className="rarityList">
                                        <div className="rarityTitle">{type}</div>
                                        <div className="rarityCount">{count}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {(selectedCardType === 'digimon' && (
                            <div className="barGraph">
                                <BarGraph title={"Level"} levelCount={sortedLevelCount} />;
                            </div>
                        ))}
                        {(selectedCardType === 'fusionworld' && (
                            <div className="barGraphModal">
                                <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                                <BarGraph title={"Combo"} levelCount={counterCounts} />
                            </div>

                        ))}
                        {(selectedCardType === 'optcg' && (
                            <div className="barGraphModal">
                                <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                                <BarGraph title={"Counter"} levelCount={counterCounts} />
                            </div>

                        ))}
                        {(selectedCardType === 'unionarena' && (
                            <div className="barGraphModal">
                                <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                            </div>

                        ))}
                    </div>
                </div>
            )}
            {cards.map((card, index) => (
                <React.Fragment key={card.uniqueKey}>
                    <ListItem
                        style={listItemStyle}
                        onContextMenu={(e) => handleRightClick(e, card)}
                        onMouseEnter={() => !showOverlay && setHoveredIndex(index)}
                        onMouseLeave={() => !showOverlay && setHoveredIndex(null)}
                        onClick={() => handleListItemClick(index)}
                    >
                        <div
                            style={{
                                ...overlayStyle,
                                opacity: hoveredIndex === index || clickedIndex === index ? 1 : 0,
                                pointerEvents: hoveredIndex === index || clickedIndex === index ? 'auto' : 'none',
                            }}
                        >
                            <Button
                                style={buttonStyle}
                                onClick={(e) => handleButtonClick(handleCardClick, card, e)}
                                disabled={isMobile && clickedIndex === null}
                            >
                                +1
                            </Button>
                            <Button
                                style={buttonStyle}
                                onClick={(e) => handleButtonClick(handleRemoveOne, card, e)}
                                disabled={isMobile && clickedIndex === null}
                            >
                                -1
                            </Button>
                            <Button
                                style={buttonStyle}
                                onClick={(e) => handleButtonClick(handleRightClick, card, e)}
                                disabled={isMobile && clickedIndex === null}
                            >
                                View
                            </Button>
                        </div>
                        <ListItemText
                            primary={
                                <div>
                                    {selectedCardType.toLowerCase() === 'digimon' || selectedCardType.toLowerCase() === 'gundam' ? (
                                        card['Category'].toLowerCase() === 'digi-egg' ? 'Egg' : card['Level']
                                    ) : (
                                        card['Category'].toLowerCase() === 'leader' ? 'L' :
                                            card['Category'].toLowerCase() !== 'leader' && card['Cost']['Generic']
                                    )}
                                </div>
                            }
                            secondary={
                                selectedCardType.toLowerCase() === 'digimon' || selectedCardType.toLowerCase() === 'gundam' ? (
                                    card['Category'].toLowerCase() !== 'digi-egg' &&
                                    <div style={{ color: Array.isArray(card['Color']) && (card['Color'].includes('Yellow') || card['Color'].includes('White')) ? 'black' : 'white' }}>
                                        Level
                                    </div>
                                ) : (
                                    card['Category'].toLowerCase() !== 'leader' && card['Category'].toLowerCase() !== 'digi-egg' &&
                                    <div style={{ color: Array.isArray(card['Color']) && (card['Color'].includes('Yellow') || card['Color'].includes('White')) ? 'black' : 'white' }}>
                                        Cost
                                    </div>
                                )
                            }
                            style={{
                                ...countStyle,
                                color: Array.isArray(card['Color']) && (card['Color'].includes('Yellow') || card['Color'].includes('White')) ? 'black' : 'white',
                                background: Array.isArray(card['Color']) && card['Color'].length > 1 ?
                                    `linear-gradient(to right, ${card['Color'].map((color, index) => `${color} ${index * (100 / card['Color'].length)}%, ${color} ${(index + 1) * (100 / card['Color'].length)}%`).join(', ')})` :
                                    card['Color'][0],
                            }}
                        />
                        <ListItemText
                            style={{ paddingLeft: '3px', width: '50%' }}
                            primary={<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#dadada' }}>{card['Card Name']}</div>}
                            secondary={<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#828282' }}>{card['CARD ID'].join("-")} : {card['Category']}</div>}
                        />
                        <div
                            style={{
                                ...imageContainerStyle,
                            }}>
                            <div style={{ ...imageStyle, backgroundImage: `url(/card_images/${selectedCardType}/${card.ArtSrc ? `${card.ArtSrc}.webp` : `${card.Key}.webp`})`, }}></div>
                        </div>
                        <ListItemText primary={card['count']} style={countStyle} />
                    </ListItem>
                    {index !== cards.length - 1 && <Divider component="li" />}
                </React.Fragment>
            ))}
        </List>
    );

    return (
        <>
            {!isMobile && handleVisibility === true &&
                <div className='DeckList'>        <List sx={style} aria-label="card list" ref={listRef}>
                    <ListItem>
                        <div className="right-side-container">
                            <div className="deckStatsList">
                                <div className={`rarityList ${totalCards > (selectedCardType.toLowerCase() === 'fusionworld' ? 60 : (selectedCardType.toLowerCase() === 'optcg' || 'digimon' ? 50 : 0)) ? "exceeded-limit" : ""} ${totalCards >= 30 && totalCards <= 49 ? "highlight-yellow" : ""}`}>
                                    <div className={"rarityTitle"}>Total</div>
                                    <div className={"rarityCount"}>{totalCards}</div>
                                </div>
                            </div>
                        </div>
                        <Button id="NavButton"
                            onClick={handleOverlayClick}>
                            Stats
                        </Button>
                        <Button id="NavButton"
                            onClick={handleClear}>
                            Clear
                        </Button>

                        <Button id="NavButton"
                            onClick={handleToggleVisibility}>Hide</Button>
                    </ListItem>
                    {showOverlay && (
                        <div style={{ ...overlayStyleDeckStats, top: '52.5px' }}>
                            <div className="overlayDeckListStats">

                                {(selectedCardType === 'optcg' || selectedCardType === 'fusionworld' || selectedCardType === 'unionarena') && (
                                    <div className="cardTypesList">
                                        {Object.entries(categoryCount).map(([rarity, count]) => {
                                            if (rarity === 'Digi-Egg') {
                                                rarity = "Egg";
                                            }
                                            return (
                                                <div key={rarity} className="rarityList">
                                                    <div title={rarity} className="rarityTitle">{rarity.substring(0, 1)}</div>
                                                    <div className="rarityCount">{count}</div>
                                                </div>
                                            );
                                        })}
                                        {Object.entries(cardTypesCount).map(([type, count]) => (
                                            <div key={type} className="rarityList">
                                                <div className="rarityTitle">{type}</div>
                                                <div className="rarityCount">{count}</div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {(selectedCardType === 'digimon' && (
                                    <div className="barGraph">
                                        <BarGraph title={"Level"} levelCount={sortedLevelCount} />;
                                    </div>
                                ))}
                                {(selectedCardType === 'fusionworld' && (
                                    <div className="barGraphModal">
                                        <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                                        <BarGraph title={"Combo"} levelCount={counterCounts} />
                                    </div>

                                ))}
                                {(selectedCardType === 'optcg' && (
                                    <div className="barGraphModal">
                                        <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                                        <BarGraph title={"Counter"} levelCount={counterCounts} />
                                    </div>

                                ))}
                                {(selectedCardType === 'unionarena' && (
                                    <div className="barGraphModal">
                                        <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                                    </div>

                                ))}
                            </div>
                        </div>
                    )}
                    {cards.map((card, index) => (
                        <React.Fragment key={card.uniqueKey}>
                            <ListItem
                                style={listItemStyle}
                                onContextMenu={(e) => handleRightClick(e, card)}
                                onMouseEnter={() => !showOverlay && setHoveredIndex(index)}
                                onMouseLeave={() => !showOverlay && setHoveredIndex(null)}
                                onClick={() => handleListItemClick(index)}
                            >
                                <div
                                    style={{
                                        ...overlayStyle,
                                        opacity: hoveredIndex === index || clickedIndex === index ? 1 : 0,
                                        pointerEvents: hoveredIndex === index || clickedIndex === index ? 'auto' : 'none',
                                    }}
                                >
                                    <Button
                                        style={buttonStyle}
                                        onClick={(e) => handleButtonClick(handleCardClick, card, e)}
                                        disabled={isMobile && clickedIndex === null}
                                    >
                                        +1
                                    </Button>
                                    <Button
                                        style={buttonStyle}
                                        onClick={(e) => handleButtonClick(handleRemoveOne, card, e)}
                                        disabled={isMobile && clickedIndex === null}
                                    >
                                        -1
                                    </Button>
                                    <Button
                                        style={buttonStyle}
                                        onClick={(e) => handleButtonClick(handleRightClick, card, e)}
                                        disabled={isMobile && clickedIndex === null}
                                    >
                                        View
                                    </Button>
                                </div>
                                <ListItemText
                                    primary={
                                        <div>
                                            {selectedCardType.toLowerCase() === 'digimon' || selectedCardType.toLowerCase() === 'gundam' ? (
                                                card['Category'].toLowerCase() === 'digi-egg' ? 'Egg' : card['Level']
                                            ) : (
                                                card['Category'].toLowerCase() === 'leader' ? 'L' :
                                                    card['Category'].toLowerCase() !== 'leader' && card['Cost']['Generic']
                                            )}
                                        </div>
                                    }
                                    secondary={
                                        selectedCardType.toLowerCase() === 'digimon' || selectedCardType.toLowerCase() === 'gundam' ? (
                                            card['Category'].toLowerCase() !== 'digi-egg' &&
                                            <div style={{ color: Array.isArray(card['Color']) && (card['Color'].includes('Yellow') || card['Color'].includes('White')) ? 'black' : 'white' }}>Level</div>
                                        ) : (
                                            card['Category'].toLowerCase() !== 'leader' && card['Category'].toLowerCase() !== 'digi-egg' &&
                                            <div style={{ color: Array.isArray(card['Color']) && (card['Color'].includes('Yellow') || card['Color'].includes('White')) ? 'black' : 'white' }}>Cost</div>
                                        )
                                    }
                                    style={{
                                        ...countStyle,
                                        color: Array.isArray(card['Color']) && (card['Color'].includes('Yellow') || card['Color'].includes('White')) ? 'black' : 'white',
                                        background: Array.isArray(card['Color']) && card['Color'].length > 1 ?
                                            `linear-gradient(to right, ${card['Color'].map((color, index) => `${color} ${index * (100 / card['Color'].length)}%, ${color} ${(index + 1) * (100 / card['Color'].length)}%`).join(', ')})` :
                                            card['Color'][0],
                                    }}
                                />
                                <ListItemText
                                    style={{ paddingLeft: '3px', width: '50%' }}
                                    primary={<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#dadada' }}>{card['Card Name']}</div>}
                                    secondary={<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#828282' }}>{card['CARD ID'].join("-")} : {card['Category']}</div>}
                                />
                                <div
                                    style={{
                                        ...imageContainerStyle,
                                    }}>
                                    <div style={{ ...imageStyle, backgroundImage: `url(/card_images/${selectedCardType}/${card.ArtSrc ? `${card.ArtSrc}.webp` : `${card.Key}.webp`})`, }}></div>
                                </div>
                                <ListItemText primary={card['count']} style={countStyle} />
                            </ListItem>
                            {index !== cards.length - 1 && <Divider component="li" />}
                        </React.Fragment>
                    ))}
                </List>
                </div>
            }
            {!isMobile && handleVisibility === false &&
                <div className='DeckList' style={{ minWidth: "fit-content" }}>
                    <List sx={style} aria-label="card list" ref={listRef}>
                        <ListItem>
                            <Button id="NavButton" onClick={handleToggleVisibility}>Show</Button>
                        </ListItem>
                        <ListItem style={{ display: "flex", justifyContent: "center" }}>
                            <div className="right-side-container">
                                <div className="deckStatsList">
                                    <div className={`rarityList ${totalCards > (selectedCardType.toLowerCase() === 'fusionworld' ? 60 : (selectedCardType.toLowerCase() === 'optcg' || 'digimon' ? 50 : 0)) ? "exceeded-limit" : ""} ${totalCards >= 30 && totalCards <= 49 ? "highlight-yellow" : ""}`}>
                                        <div className={"rarityTitle"}>Total</div>
                                        <div className={"rarityCount"}>{totalCards}</div>
                                    </div>
                                </div>
                            </div>
                        </ListItem>

                    </List>
                </div>
            }
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                <div className="DrawerWindow" >
                    <div style={{ marginTop: '48px', textAlign: 'right' }}>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleDrawerToggle}
                            aria-label="close"
                            sx={{ position: 'absolute', right: 15, top: 8 }}
                            style={{ backgroundColor: 'white', zIndex: 5, width: '32px', height: '32px' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div>
                        {/* Content of the drawer */}
                        {renderCardList()}
                    </div>
                </div>
            </Drawer>
            <div className='container' style={{ position: 'fixed', bottom: '50px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Fab
                    color="primary"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    style={{ marginLeft: "100px", width: '50px', backgroundColor: 'rgb(30 31 33 / 0%)' }}
                >
                    <img src="./assets/Bordered.png" alt="Open Deck" style={{ width: '100%', zIndex: 15 }} />
                </Fab>
            </div>

        </>
    );
};

export default CardList;
